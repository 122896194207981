@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  display: grid;
  row-gap: var.$spacing-regular;
  letter-spacing: normal;
  padding: var.$spacing-regular;
  background-color: #fff;
  border-radius: var.$radius-sm;

  input {
    @extend %input;
  }

  select {
    @extend %select;
  }

  header {
    display: grid;
    row-gap: var.$spacing-compact;
    margin-bottom: var.$spacing-sm;

    h2 {
      @extend %h2;
    }

    .note {
      @extend %note;
    }
  }

  [data-ui='input-suffix'] {
    @include mix.flex;

    margin-left: var.$spacing-sm;

    &:is(input:disabled + [data-ui='input-suffix']) {
      margin-left: 0;
    }

    .units {
      @extend %label;
    }
  }

  .saveBtn {
    @extend %btn-primary;

    justify-self: flex-end;
  }
}
