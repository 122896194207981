@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  border: 1px solid var.$lightNeutralGray;
  border-radius: var.$radius-md;

  &:has(.accordion[aria-expanded='true']) {
    border-color: rgba(var.$on3primary, 0.25);
  }

  .accordion {
    @extend %btn-icon, %btn-flat;

    @extend %h4;

    color: var.$gray;
    background-color: rgba(var.$focusBackground, 0.5);
    width: 100%;

    &[aria-expanded='true'],
    &:hover {
      color: var.$on3primary;
      background-color: rgba(var.$on3primary, 0.05);
    }

    .arrow {
      margin-left: auto;

      &:is([aria-expanded='true'] .arrow) {
        transform: rotate(180deg);
      }
    }
  }

  .panel {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: var.$spacing-sm;
    padding: var.$spacing-sm;

    .latestItem {
      text-align: left;
      padding: var.$spacing-sm;
      color: var.$black;

      &:hover {
        background-color: rgba(var.$on3primary, 0.05);
        border-radius: var.$radius-sm;

        dt {
          color: var.$on3primary;
        }
      }

      dl {
        display: grid;
        gap: var.$spacing-xs;
      }

      dt {
        @extend %label;

        color: var.$gray;
      }

      dd {
        letter-spacing: normal;
        font-size: 0.875rem;

        @include mix.spaceX(var.$spacing-xs);

        .unit {
          font-size: 0.625rem;
        }
      }
    }
  }
}
