@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  border: 1px solid var.$lightNeutralGray;
  border-radius: var.$radius-md;
  overflow: hidden;

  &:has(.accordion[aria-expanded='true']) {
    border-color: rgba(var.$on3primary, 0.25);
  }

  .accordion {
    @extend %btn-icon, %btn-flat;

    @extend %h4;

    color: var.$gray;
    background-color: rgba(var.$focusBackground, 0.5);
    width: 100%;

    &[aria-expanded='true'],
    &:hover {
      color: var.$on3primary;
      background-color: rgba(var.$on3primary, 0.05);
      box-shadow: 0 2px 8px rgb(0 0 0 / 5%);
    }

    .count {
      font-weight: 400;
      font-size: 0.875em;
    }

    .arrow {
      margin-left: auto;

      &:is([aria-expanded='true'] .arrow) {
        transform: rotate(180deg);
      }
    }
  }

  .vitalList {
    display: grid;
    gap: var.$spacing-sm;
    padding: var.$spacing-sm;

    &.collapsed {
      display: none;
    }

    .addBtn {
      @extend %btn-small, %btn-icon, %btn-flat;

      color: var.$on3primary;
      justify-self: center;

      &.active,
      &:hover {
        background-color: rgba(var.$on3primary, 0.1);
      }
    }

    .vital {
      &:not(:first-child) {
        border-top: 1px solid var.$lightNeutralGray;
        padding-top: var.$spacing-sm;
      }
    }
  }
}
