@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.vital {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'date results';
  column-gap: var.$spacing-regular;
  align-items: center;
  padding: 0 var.$spacing-sm;

  &:has(.action .removeBtn) {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'date results action';
  }

  @media screen and (min-width: var.$mid-width) {
    padding: 0;
  }

  input {
    @extend %input;
  }

  .results {
    grid-area: results;

    input {
      width: clamp(0px, 100%, 250px);
    }

    [data-ui='input-suffix'] {
      @include mix.flex;

      margin-left: var.$spacing-sm;

      &:is(input:disabled + [data-ui='input-suffix']) {
        margin-left: 0;
      }

      .units {
        @extend %label;
      }
    }
  }

  .date {
    grid-area: date;
    position: relative;

    input[type='date'] {
      @extend %input-date;

      @media screen and (max-width: var.$mid-width - 1) {
        width: 0;
        opacity: 0;
        padding: 0;
      }
    }

    [data-ui='input-prefix'] {
      padding: 0;
      border: none;

      @media screen and (min-width: var.$mid-width) {
        display: none;
      }
    }

    .calendar {
      @include mix.flex($justify: center, $gap: var.$spacing-compact);

      .icon {
        height: 1rem;
        width: 1rem;
        fill: currentcolor;
      }

      span {
        letter-spacing: 0.15px;
        font-size: 0.75rem;
      }
    }
  }

  .action {
    grid-area: action;

    .removeBtn {
      svg {
        font-size: 1.2rem;
        fill: var.$on3primary;
      }
    }
  }
}
