@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  display: grid;
  gap: 2px;

  .label {
    @extend %label;

    &.srOnly {
      @extend %sr-only;
    }
  }

  .height {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 100px));
    column-gap: var.$spacing-sm;
  }

  [data-ui='input-suffix'] {
    @include mix.flex;

    @extend %label;
  }
}
