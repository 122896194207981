@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  @include mix.spaceY(var.$spacing-regular);

  .header {
    @include mix.flex(
      $direction: column,
      $justify: space-between,
      $align: flex-start,
      $gap: var.$spacing-sm,
      $wrap: wrap
    );

    @media screen and (min-width: var.$tablet-width) {
      flex-direction: row;
      align-items: center;
    }

    h1 {
      @extend %h3;
    }

    .addBtn {
      @extend %btn-small, %btn-icon, %btn-flat;

      color: var.$on3primary;
      align-self: center;

      &.active,
      &:hover {
        background-color: rgba(var.$on3primary, 0.1);
      }
    }
  }

  .form {
    display: grid;
    gap: var.$spacing-lg;

    .section {
      display: grid;
      gap: var.$spacing-sm;

      h2 {
        @extend %h4;

        letter-spacing: 0.15px;
      }
    }

    .vitals {
      columns: 1;

      @include mix.spaceY(var.$spacing-sm);

      @include mix.on3MQ(
        $lg: var.$full-width,
        $md: var.$desktop-width,
        $sm: var.$sidebar-break
      ) {
        columns: 2;
      }
    }

    button[type='submit'] {
      @extend %btn-primary;

      justify-self: flex-start;
      grid-column: 1;

      @media screen and (max-width: var.$tablet-width - 1) {
        &:not(:disabled) {
          position: fixed;
          z-index: 2;
          bottom: 1rem;
          left: 1rem;
          font-size: 1rem;
        }
      }
    }
  }
}
